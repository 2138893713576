<template>
    <div class="articles">
        <banner></banner>
        <div class="site-content animate">
            <!-- 文章目录 -->
            <!-- <div id="article-menus">
                <menu-tree :menus="menus" child-label="child"></menu-tree>
            </div> -->
            <main class="site-main">
                <article class="hentry">
                    <!-- 文章头部 -->
                    <header class="entry-header">
                        <!-- 标题输出 -->
                        <h1 class="entry-title">关于疫情</h1>
                        <hr>
                        <div class="breadcrumbs">
                            <div id="crumbs">最后更新时间：2022年12月17日</div>
                        </div>
                    </header>
                    <!-- 正文输出 -->
                    <div class="entry-content">
                        <p>@[TOC]</p>
                        <h4 id="引言：">引言：关于疫情这件事</h4>
                        <blockquote>
                            <p style="text-indent: 2em;">
                                2019年10月16日，身为计算机专业的我，是要为自己以后的路做点什么，于是，就走上了程序这条路，成为一位前端工程师，开课时间是在11月底，并不知道自己会学成什么样子，况且自己的学习能力是那么的菜，这件事情和家人也是商量了很久，毕竟学费不是小数目，最后家人还是同意了我的想法，什么也别说了，努力就会有结果。
                            </p>
                        </blockquote>
                        <blockquote>
                            <p style="text-indent: 2em;">
                                开课后，从基础一点一点的来，还是能接受的，只不过自己的逻辑能力有点差，这就是自己为啥只能上大专的结果，逻辑能力那就是慢慢练。
                            </p>
                        </blockquote>
                        <blockquote>
                            <p style="text-indent: 2em;">
                                不多久，在其他班级遇到了一个好看的女孩子，是喜欢的那种感觉，好吧，其实是馋她的身子(狗头)，一开始，并不知道是哪个班的，经过几天的观察，是ui班级的，于是在快放假的时候，让班里的其他女生帮忙要她的微信号，结果真的要到了，临走的前一天晚上，我把她的微信号和qq一起加了，qq是微信号里边带的，第二天早上醒来，发现两个她都同意了，但她并不知道我是谁，这天该回家了，大早上收拾好东西，拉着行李箱出了大门，就在这时起起来她的时候，发现她就从学校里面出来，我也不太确定是不是，反正就是之前见到她的那种感觉，应该没错，看着她走到302公交车里，我发现自己很怂，也不敢确定到底是不是，因为见的并不多，就这样，我也坐上了出租车去了中心汽车站，坐车回家了，在车上和她聊了好久。问了她今天的在门口是不是她，她说是的，今天是1月20号。
                            </p>
                        </blockquote>
                        <blockquote>
                            <p style="text-indent: 2em;">
                                到了家，已经是下午了，这时候和小伙伴们去街上洗澡，我很清楚自己在洗澡堂受不了那种热度和大脑缺氧，果然，到了里面，我就冲了一会，感觉到眩晕，听不到别人在喊我，难受至极，然后我就出来，透透气，小伙伴们都吓坏了，缓过来之后，感觉胸口有伤口，不知道什么时候碰的，洗完澡回家，这时候，历史级病例出现了，死都没有想到会延续那么长时间，不过在这天1月20号，我才重点关注到，据说在12月就已经出现了，武汉。
                            </p>
                        </blockquote>
                        <blockquote>
                            <p style="text-indent: 2em;">
                                这天过后，消息一直在增加，武汉很严重，封城了，我也没有想到会这么严重，也不知道当时封城是为了干嘛，慢慢的，消息每天都有病例增加，才明白了，那时候，也有一个人来买口罩，一下要完，我当时傻b了，他说要发给员工，当时都大年30 了，屁的员工，果断被骗了，在过了几天，乡下也开始封村了，进来超市内要检测，学校里也可能是见开学不了，就进行了线上直播，一开始学了几天的新知识，然后就开始复习之前的js，就这样学了大概半个月，按照正常情况下来说，那时候该是开学的时候了，但是开不了，我很想回到学校，去见那个女孩，奈何情况不允许，我当时在家受到了家人的各种不理解，真的就待不下去了，没法子，学校也回不去。
                            </p>
                        </blockquote>
                        <blockquote>
                            <p style="text-indent: 2em;">
                                不知不觉，已经到了3月份， 鬼知道什么时候能结束，怎么也见不到那个女孩，很烦很烦，在家上网课也非常的不爽，本来6个月能结束的，赶紧出去找工作还贷款，硬生生的能拖到8个月，4月份的时候，眼看着有新的消息，谁知郑州来了一个毒王，从国外入境，突然就失望，于是又得在家待着，从这之前起，心情就一直没有好过，到了四月份，各种花都开了，也能下地看看各种景色，家乡的大风车也慢慢多了起来，心情就有所好转，慢慢的，就到了6月份，此时郑州慢慢解封了，赶着毕业季，我们也能去郑州了，不过这个女孩子，已经开始不聊了，从那开始就已经断联系了。
                            </p>
                        </blockquote>
                        <blockquote>
                            <p style="text-indent: 2em;">
                                6月12号，当天早上下着大雨，我拉上行李箱，来到了汽车站，买上了到郑州的票，不一会车走了，当时在家真的烦透了家人的各种不理解，但我只能忍声吞气，毕竟是家人，不想跟家人发生太多矛盾，全是这次疫情阻挡了我的各种路，车出站了，路上被淹了，雨下的很大，在车上，打开酷狗，听着夜色，发现夜色的MV回来了，我一气之下，下载了下来，并存到了百度网盘里，永久性保存，到了郑州后，来到了学校门口，当天的想法是在网吧包一夜，第二天学校才开门，也是很严格的，各种扫码登记，进到学校里，在到宿舍里，宿舍一片狼藉，我们必须收拾完所有的东西离校，因为我们毕业了，学校也不能住人，课程也没有学完，只能去云和总部去学习，总部给租了一套间，我们5个人住。一天没有吃东西，全是收拾了，拉着行李箱乱跑。昨天一夜没睡，白天也睡不了。累瘫了。
                            </p>
                        </blockquote>
                        <blockquote>
                            <p style="text-indent: 2em;">
                                在这里，一共学到8月多份，中间的学习很吃苦，最终熬了过来，很怀念在秦庄嘉园的日子，是在32楼，风景很好看，是要走了，毕业证也领了，当时很憧憬深圳的生活，于是就和家里人商量，最后，家人同意了，也是因为距离远，从这时候到之前，没有和那女孩说过一句话了，甚至没有见面，见面也没有打招呼。
                            </p>
                        </blockquote>
                        <blockquote>
                            <p style="text-indent: 2em;">
                                收拾完行李后，我们随着老师坐上了晚上的火车，一路到深圳，那时候的疫情不算是很严重，第二天下午，到了深圳，感受到了深圳的气候，微风中带点热，我们跟着老师来到了住的地方，，讲了面试的技巧，第二天，开始投简历，这天没有收到几个面试，好的是，我再面试的第二天，就通过了。这次面试还是很幸运的，本想着贷款不知道要怎么还，还好。在没有疫情的情况下，6个月结束课程，3个月的找工作时间，肯定是能找到的，9个月每月还几十块钱的已经用完了，每月开始1500的还，绝对可以，现在8个月过去了，也要开始1500的还，打破了课程的计划和还款的计划。更加紧迫。
                            </p>
                        </blockquote>
                        <blockquote>
                            <p style="text-indent: 2em;">
                                就这样，就开始上班了，开始了深圳的生活，在那半年里，深圳没有出现太多的病例，一切都照常上班，过年回家的时候，担负着抢来回票和做核酸的重任，做核酸要花钱，在公司放假前两天，我就已经请假了，在家里做好核酸，坐私家车回家，那时候深圳有政策，从外地回来的要可能隔离，自费，我当时是打着离职的心态去请假了，谁知道请多了因为疫情老板也不给，我也是能理解，可是回到家后，并没有要什么核酸证明，还没有抢到回来的票，我只能又请一天假，抢到了郑州中转的高铁，晚了一天回公司。
                            </p>
                        </blockquote>
                        <blockquote>
                            <p style="text-indent: 2em;">
                                年后回来后，就想着把剩下所有的贷款一次性还完了吧，每月1500的，还有那么的利息，看了下自己的总余额，只能再问朋友借上几千，才能还完，过了一个月后，发了工资，把欠朋友的也一次性还完了，此时真的是穷到底了，还剩一点坚持下个月发工资，下个月发工资刚好就剩了几十块钱。
                            </p>
                        </blockquote>
                        <blockquote>
                            <p style="text-indent: 2em;">
                                2021年上半年，深圳出现病例，一个月做了好多次，那时候就感觉做的核酸要顶两年的了，慢慢的也是好了起来，下半年，国庆节回家了一趟，也没有任何问题，买下了人生第一辆车，本来着自己的比亚迪唐由于家人不太喜欢，所以我就放弃了，想了好几年的车，最终还是没有买下来，回来深圳后，也是一切都好，晚上下班后还能出来跑跑步。
                            </p>
                        </blockquote>
                        <blockquote>
                            <p style="text-indent: 2em;">
                                一直到了年底，要该回去过年了，往年家人都催的很紧，要回去相亲，我并不想，也很烦，也没办法，只能硬着头皮去辞职了，2021年12月底，开始提了离职，但是家人又觉的可惜，又不让辞，我能怎么办呢，犹豫就会败北，只能这样了，中间，公司年会，去了大鹏杨梅坑，第二天醒来，坂田出现病例，我当时人都呆了，突然一下，坂田被隔离，14号离职申请也下来了，估计也是回不来老家，果然没有错，14号那时候，这边还是一天一天的核酸，就别说回家了，家里管控的也是很严，就这样，一直待到了1月28号才回家，26号深圳摘星，此时，已经没有高铁票了，只能坐私家车了。
                            </p>
                        </blockquote>
                        <blockquote>
                            <p style="text-indent: 2em;">
                                到了家后，果然家里管控的特别严，此时我不能回到家里，影响生意，就只能在我叔的家里住着，那里啥都没有，就是一片荒凉，还好有时小伙伴去找我玩会，在那里待了一个星期差不多，中间在大年30的时候，深圳又出现病例，原来星星刚摘掉没多久，就又上去了，我在家睡了一晚上，第二天，又回到了荒凉的地方，晚上睡觉都是开着灯，窗口还关不上，后来我没管那么多了，回到了自己的屋里，就这样过了15后，邻居说是要该回深圳了，本来是20多号回去，她要提前，我也就一起回去就行了，当时深圳还是有病例。
                            </p>
                        </blockquote>
                        <blockquote>
                            <p style="text-indent: 2em;">
                                我们一起去做了核酸，第二天收拾好东西后，我爷爷还是拿来了金典让我带到了深圳，正常情况下，没必要带着，但是老人说让我拿，我就必须拿，没有任何怨言，不管别人怎么说我，我都要带，毕竟老人年纪大了，我要听话。这天是正月18号。
                            </p>
                        </blockquote>
                        <blockquote>
                            <p style="text-indent: 2em;">
                                到了深圳已经是10点多了，还必须要做次核酸才能出站，我拿着好多的行李，要累瘫，出来后，到了地铁，感受到深圳的气息真的好，第二天，深圳就开始降温了，还好我穿着比较厚的衣服，依然还是每天核酸，那么冷的天，下着雨，排队，做核酸，我也要开始找工作了，由于疫情，确实不是很好找，第一周成了两家，但是我都不想去，一家小工作室，一个大小周。
                            </p>
                        </blockquote>
                        <blockquote>
                            <p style="text-indent: 2em;">
                                后来越来越难了，中间疫情严重，深圳市全封了一周，更是有点小慌，本想着自己来到能找到10k以上的，但并没有那么好，全是疫情，解封后的第一天，接着面试，有约到星河那里的，也不错，我面试都通过了，但是，被放鸽子了，我很气愤，平稳心态继续来，很难，但没想到这么难，接着又接到了一个电话，在凉帽山，感觉这家还是很不错的，我面成了，但是工资压的很低，双休，紧接着就直接上班了。此次面试大概经历了一个多月，中间封了一星期。
                            </p>
                        </blockquote>
                        <blockquote>
                            <p style="text-indent: 2em;">
                                2022年3月29日，我入职了，从那时起，原来的一天一次核酸，到后来的两天一次核酸，24小时内的，48小时内的，后来的72小时内的，反正是没有停，一直核酸，晚上下班甚至到10点多才到家，可以说，从年前那次坂田出现病例开始，只要再深圳，核酸就没停过，最好的一段时间是72小时内的，这样维持了一段时间后，又出现了病例，又回到了一天一次的时候。
                            </p>
                        </blockquote>
                        <blockquote>
                            <p style="text-indent: 2em;">
                                到今天是第一次编写关于疫情这件事，只描述了疫情的事件，2022年6月27日，我离转正还有两天，不知道在这家公司能待多久，疫情打乱我所有的计划，发现自己的经历都挺反方向幸运的，全让自己摊上了，现在好好待着，不乱动，下次跳一定要找好的时机，这段时间，严谨时刻还没过去，还是要一天一次。已经3年多时间了。
                            </p>
                        </blockquote>
                        <blockquote>
                            <p style="text-indent: 2em;">
                                2022年8月底左右，深圳再次爆发严重疫情，这次在不知不觉中，突然就多了很多病例，导致9月初那两天在家居家办公，在这之前，一周左右新增将近300，8月底晚上，公司突然通知吉华街道很严重，要求当天晚上9点后要所有人撤离园区，我直接就想到可能是要封了，但是又想到一个严重的问题，我电脑没有开远程，果然，不出一会，又通知吉华街道封3天，到周六，真他么离谱，之前一直开着远程也一直用不到，就关掉才几天，就直接用上了，而且我现在也回不去公司了，王琰个老六，还一直在群里BB，听说公司还有人，让开远程，又说到点了，要走了，又通知说1号上午12点以后全部封闭，所以没办法了，只能亲自去公司一趟了。第二天起来直接去公司，做地铁走到半路，又接到通知，公司附近的地铁站被封了，我去了还有个屁用，还好公司有个人在，只能让她帮我开下远程了，回来之后试了下，还好是能用，不管了，在家安心的办公就行了，在家办公有好处也有坏处，好处是不用起那么早了，在家办公很随意，想干嘛干嘛，不好的是，远程办公有些吃力，不是很顺畅，忍忍吧，任务很紧，但时机不支持，没办法，爱咋样咋样，这段时间增加的病例也是很多，南坑又出现了病例，周末两天也是封存，我再这家公司待的真是一言难尽。
                            </p>
                        </blockquote>
                        <blockquote>
                            <p style="text-indent: 2em;">
                                关于在这家公司待这段时间的感触，我对部门经理很无语，跟个傻B一样，办事邋里邋遢，还怪员工们做的不好，操作电脑就像是小学生进网吧一样，生硬的不能在生硬了，技术总裁更是傻B一样，只知道技术之间的联系，根本不知道怎么实现出来的，就瞎指挥，瞎确认，对于这家公司领导人是怎么样的我不管，我只管技术的，技术还一直是前后端不分离的，部门经理太傻逼，不会搞，一直这么操作下去，对我是很不友好的，我提出来要求前后端分离，但是没有用，我不管那么多了，要不是今年疫情这样子，我早都跳走了，但也是为了工作经历时间，忍忍，也需要多待着吧，，是想着等到考完成考之后，或者明年，在考虑跳，又听说，这家连年终奖都没有，我更是服老六，截止到今天是918编写，今天是被防控警报给吵醒了，这次疫情已经快严重持续一个月了，每天都是一次核酸，虽然之前也没有停过，但这段时间是很严重，从好的状况3天一次核酸变成1天一次，不知道这次还要维持多久，一天一次还没有结束，希望这篇文章能结尾。
                            </p>
                        </blockquote>
                        <blockquote>
                            <p style="text-indent: 2em;">
                                过了一段时间，马上就来到了成考的日期，本来一开始的时间是10月中旬，当时我在想，由于深圳的原因能不能回去，深圳连车票也不敢买，后来，国庆节的时候，老家突然就严重了起来，全城封禁，紧接着，成考的时间发布又推迟到了11月56号，本来是想着，国庆节过后，直接买票回家考试，可以在家待一周，今年没有再次跳槽的原因也是因为考试，考试推迟了，就重新规划时间，10月底回去也必须是在家待上一个星期，在10月20左右，我买了深圳到郑州的飞机票，然后家里第二次严重，但是飞机票已经买了，在退的话是要手续费的，可是，做飞机太麻烦了，还要经过郑州，郑州的疫情也不减，真的还不如做高铁，不知道当时是怎么想的，想直接买飞机票，过了一段时间是真的没办法了，深圳也严重，郑州也严重，不得不退了飞机票，直接买高铁，幸运的是退飞机票的时候，检测到飞往郑州是高风险地区，所以没有扣我手续费，等吧，等到快考试了，在买高铁，反正也没多少人买。
                            </p>
                            <p style="text-indent: 2em;">
                                终于，快到考试了，老家又双叒叕封起来了，一个月差不多是3次了，当然此时的深圳也是一如既往的一天一次，我再等，果然考试再次推迟，具体时间另行通知，本来打算着考完试回来买手机，现在就不考虑那么多了，直接买就行了，一共是换了两部magic4pro，颜色不一样，后来magic4pro太不给力了，屏幕垃圾的要死，性价比不如mate50了，magic4是4月份上市，mate50是9月份上市，要换50的话，还要抢，此时我的荣耀v20已经摔的没有颜值了，我等不了，直接去线下看了手机，要抢的mate50曜金黑昆仑玻璃的还真的有，直接就过去买，手机刚买过来一天后，我老家朋友，和我室友，在此的前两天有去过甘坑玩，再去的时候，街道办打电话说，当时他们坐的同一列车上有一阳性，所以我们要隔离3天，幸亏我那两天是周六加班，周一调休，我没有去公司，自己成了次密接，居家办公3天，第一次被针对这么惨，隔离就隔离吧，好在是我们都是阴性，没有一点事情，这才是躲过了一劫，不过还是一天一次，今天是11月26日，今天编写了两段文章，现在已经不管考试了，等到了12月份直接提离职，早早的回老家，没有一点顾虑。
                            </p>
                        </blockquote>
                        <blockquote>
                            <p style="text-indent: 2em;">
                                今天是20221203，这两天深圳突然放开，不再做核酸了，非必要情况下，公共场所交通场所也不用扫码了，恢复到了以前的平静，但是疫情形式并没有减轻，这是怎么回事呢
                            </p>
                        </blockquote>
                        <blockquote>
                            <p style="text-indent: 2em;">
                                今天是20221205，昨天今天才得知，不仅是深圳放开，原来是国家对于核酸检测，出示绿码全面放开，也就是不再会强迫着去做核酸，和出示24小时或者48小时之内的核酸证明。
                            </p>
                            <div style="text-indent: 2em;">
                               人民日报文章：
                               <br>
                               <h2>再见，核酸</h2>
                               以后，再也没有通知检测核酸了，再也不会有人查验你的健康码，行程码，核酸码，也意味着从现在开始，我们身上的三层防护衣被国家正式收回，只有失去才会明白，你才知道曾经拥有什么，为什么拥有，你才知道什么叫故失去，才能慢慢体会到失去的难过。
                               <br>
                               我们三年没有被感染，不是因为幸运，也不是因为你免疫力有多强，是因为这三年来，每一个接近你的人国家都帮你筛查一遍，都是健康无风险的，只要有一点风险，国家都把他和你隔开，使你免遭病痛折磨。国家尽力了！从现在开始，请大家真的要戴好口罩做好防护，自求多福。
                               <h2 style="text-align: right;">——人民日报</h2>
                            </div>
                            <div style="text-indent: 2em;">
                                对于上述文章感想：国家为了保护我们的安全，真的是尽力了，2019年12月至今，也是整整三年了，也将成为历史，由此，感谢祖国三年来无声守护，接下来是靠我们自己照顾好自己，也不要给国家添乱，自己做好防护，同时也希望家人，喜欢的人，身边的人一直会平平安安。
                                <div style="text-align: right;">——20221205编写</div>
                            </div>
                        </blockquote>
                        <blockquote>
                            <p style="text-indent: 2em;">
                                20221205-20221217，在这两周内，也是疫情刚放开的一个阶段，也不算高峰期，但也有很多人被感染，全面放开后，阳性的人可以到处乱跑，再也不会像以前一样，被单独隔离起来，因为这样，才导致很多人，也被感染，其实早该到这一步的，国家放开是因为想让人们自己能够抵抗的住，才算是疫情真的结束了，这只是刚开始的一个阶段，挺住就可以，现在是疫情制度结束了，但是病情并没有结束，我身边的很多朋友，认识的很多朋友，都已经阳性了，三年之内，我没有被感染，感谢国家，放开后，现在的我还没被感染，这不是幸运，每天很努力的控制住自己，在地铁内口罩捏的最紧，在公司内，口罩一会都不能摘，我再这个阶段安然无恙，但也不能放松，希望一直这样下去。20号离职完毕，突然做个决定，大约在12月25日回老家。一路生花。
                            </p>
                        </blockquote>
                        <blockquote>
                            <p style="text-indent: 2em;">
                                时隔半年，再次想起关于疫情的文章，今天是2023年5月13号，上段也说了，在那两周内，我撑过去了，但是20号离职后的三天内，我也被感染了，3天内也是很少出门，但是就是感染了，我怀疑的是在公司被传染了，潜伏了3天，感染的那天晚上，也是因为洗了个很长时间的澡，洗完澡之后，就在屋里一直打游戏，打到半夜，感觉到很不舒服，头疼加身上很冷，我就知道自己阳了，那时候也是半夜了，不想打扰二弟，此时二弟已经熟睡，躺在被窝里，身体一直抖，没办法，只能撑过晚上吧，第二天早早的就醒了，睡不着了，喉咙也很难受，到7点多，喊醒二弟，这个身体是真的不想动，身体酸痛，腰都直不起来，身上还很冷。
                            </p>
                            <p style="text-indent: 2em;">
                                本来计划在25号回家，也别想了，至少等一周再回吧，那时看病也很难，随着二弟的鼓劲，还是起来去看病吧，虽然身体很难受，走路都费劲，不是盖的，这新冠真带劲，走了好久，才到了社康，里面人很多，也不管那么多了，赶紧去挂号，挂完发现前面还要等20几个人，真的够折磨，我和二弟坐在对面台阶上晒太阳，等了好久才到我，进去之后，我问医生可以打针吗，因为当时真的难顶，医生说，现在资源紧张，药都很难搞到，所以没办法，只能挺，给我开了退烧药，双黄连，还有贴在耳朵上的啥东西，回去勉强吃了一点东西，赶紧吃退烧药，下午感觉是退了一点，但还是很高，38左右，到了晚上，吃了点东西，再次嗑药，这次身穿睡衣，盖上两个被子，准备发育，在被窝里捂上一个小时，中间也没有睡着，从11点到12点，不知不觉的，身上开始湿了，全身都是汉，接近一个小时的时候，头发湿了，身体全湿，就连睡衣也是湿的，差不多， 起来上个厕所，脱完衣服，准备睡觉。
                            </p>
                            <p style="text-indent: 2em;">
                                早上又是早早的醒了，因为喉咙很不舒服，不喝水太干了，还那么痛，当时感觉身上已经退烧了，天亮量了个体温，已经降到了37，身上也不是那么酸痛了，就是喉咙很不舒服，上午又嗑药，使劲喝水，终于到了下午，体温降到了37以下，为了保持，不停的喝水，一直喝水，喉咙就好很多，退烧药也嗑完了，又是一天早上，喉咙还是很难受，还是要起来，去拿点退烧药备着，生怕医生不给开，资源很紧张，结果还是给拿了两片，先留着，然后耳朵上的贴的也换了，又是歇了一两天，除了喉咙难受，其他也没什么了，然后订了12月30号从深圳北到永城北的高铁，这次真的能回家了，一周的病情也算是扛过去了，多亏了有二弟，二弟有着很大的作用。二弟MVP。
                            </p>
                            <p style="text-indent: 2em;">
                                回家的这天早上，收拾了一番，拉上行李箱，背上背包，二弟送我到路口，坐上的的，他才回去，喉咙也是巨难受，到了高铁站，过了一会，喉咙才好一点，不是那么难受，上了高铁，直接来一首i'll be back，这次回去，不知道什么时候还会回来，一路上，吃了一点东西，啥也不想吃，家里是真的冷，先好好休息一天。到家的第一次相亲，我啥也不说了，傻批媒人，在夏邑县绕了很久，这次就当没有发生过。
                            </p>
                            <p style="text-indent: 2em;">
                                2023年了，又是在媒人的介绍下，认识了一个女孩，本来那天下午是要去姥姥家的，只在姥姥家待了一会，就回来见那个女孩了，从一开始见面，到后面发生了很多大大小小的事情，我也是一言难尽，也不想再去说了，就当这段感情没有经历过，见面后，到送小礼，订婚，闹脾气，回来深圳后没多久，退婚，结束。啥也不说了，以后就证明自己吧，因为这是关于疫情的文章。
                            </p>
                            <p style="text-indent: 2em;">
                                2023年3月27，我再次入职，一直到今天，2023年5月14号，这段是写了两天，从去年疫情放开，也意味着长达3年历史性的疫情是要结束，从去年回到老家后，没人在提起疫情，似乎人们已经把它抛弃了，回来深圳后，慢慢的慢慢的，地铁上的一部分人都开始不带口罩了，疫情是真的结束了吗，不知道，以后怎么样也不敢确定，距上一次写文章已经过去了半年，疫情在这半年内消失的很快，从此疫情事件告一段落，我这篇文章也告一段落。今天是2023年5月14日。
                            </p>
                        </blockquote>
                        <hr>
                        
                        <pre><code> 未完待续~~~</code></pre>
                    </div>
                    <!-- 文章底部 -->
                    <!-- <section-title>
                        <footer class="post-footer">
                            阅读次数
                            <div class="post-like">
                                <i class="iconfont iconeyes"></i>
                                <span class="count">685</span>
                            </div>
                            文章标签
                            <div class="post-tags">
                                <i class="iconfont iconcategory"></i>Web
                            </div>
                        </footer>
                    </section-title> -->
                    <!--声明-->
                    <div class="open-message">
                        <p>声明：大林哥日志|版权所有，违者必究|如未注明，均为原创|本网站采用<a href="https://creativecommons.org/licenses/by-nc-sa/3.0/" target="_blank">BY-NC-SA</a>协议进行授权</p>
                    </div>
                </article>
            </main>
        </div>
    </div>
</template>

<script>
    import Banner from '@/components/banner'
    import sectionTitle from '@/components/section-title'
    import menuTree from '@/components/menu-tree'
    import './css/articles.less'
    export default {
        name: 'articles',
        data(){
          return{
              showDonate: false,
              menus: []
          }
        },
        components: {
            Banner,
            sectionTitle,
            menuTree
        },
        methods: {
          fetchH(arr,left,right){
              if (right) {
                  return arr.filter(item => item.offsetTop > left && item.offsetTop < right)
              }else {
                  return arr.filter(item => item.offsetTop > left)
              }
          },
          // 生成目录
          createMenus(){
              let arr = []
              for(let i=6;i>0;i--){
                  let temp = []
                  let e = document.querySelector(".entry-content").querySelectorAll(`h${i}`)
                  for (let j=0;j<e.length;j++){
                      let child = this.fetchH(arr,e[j].offsetTop,(j+1 === e.length)?undefined:e[j+1].offsetTop)
                      temp.push({
                          h: i,
                          title: e[j].innerText,
                          id: e[j].id,
                          offsetTop: e[j].offsetTop,
                          child
                      })
                  }
                  if (temp.length){
                      arr = temp
                  }
              }
              this.menus = arr
          }
        },
        mounted(){
            this.createMenus()
        },
        created() {
            
        }
    }
</script>
